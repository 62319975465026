/* eslint-disable no-restricted-imports */
import { Video } from '../pages/Video';
import { Webview } from '../pages/Webview';
import { PageType } from '../types/configuration';
import { BottomTriangle } from 'components/BottomTriangle';
import { AppContext, AppContextType } from 'context';
import { AnimatePresence } from 'framer-motion';
import { BackgroundOnly } from 'pages/BackgroundOnly';
import { Flipbook } from 'pages/Flipbook';
import { Gallery } from 'pages/Gallery';
import { HomePage } from 'pages/HomePage';
import { IdlePage } from 'pages/IdlePage';
import { KeyPlan } from 'pages/KeyPlan';
import { useCallback, useContext, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { createUseStyles } from 'react-jss';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const SCREENSAVER_TIMEOUT = 600000; // 10 minutes

export const AppRoutes: React.FC<{
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShowAlert }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { ready, configuration, updating } =
    useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const handleOnIdle = useCallback(() => {
    if (!updating) {
      navigate('/idle-page');
    }
  }, [navigate, updating]);

  useIdleTimer({
    timeout: SCREENSAVER_TIMEOUT,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    // If The app is built as a React app, the browser supports the service
    // worker api, but the service worker is not loaded, reload the page to make
    // sure that the service worker is available
    if (!window.electron && 'serviceWorker' in navigator) {
      if (!navigator.serviceWorker?.controller) {
        window.location.reload();
      }
    }
  }, []);

  if (updating || !ready) {
    return (
      <div
        className={classes.emptyPage}
        style={{
          backgroundColor: '#242424',
          color: '#fff',
        }}
      >
        {updating && <h1>Updating...</h1>}
      </div>
    );
  }

  const pageComponents: PageType[] = configuration.pages;

  const pageComponentsMap = {
    Gallery,
    Flipbook,
    KeyPlan,
    BackgroundOnly,
    Video,
    Webview,
  };

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        {pageComponents.map((page: any, id: number) => {
          const DynamicPage =
            pageComponentsMap[page.type as keyof typeof pageComponentsMap];
          return (
            <Route
              path={page.path}
              element={<DynamicPage {...page} />}
              key={id}
            />
          );
        })}

        <Route
          path="/"
          element={
            <HomePage backgroundImage={configuration.home.backgroundImage!} />
          }
        />

        <Route path="/idle-page" element={<IdlePage />} />
      </Routes>
      {!window.electron && <BottomTriangle setShowAlert={setShowAlert} />}
    </AnimatePresence>
  );
};

const useStyles = createUseStyles({
  emptyPage: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
