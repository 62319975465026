import { Styles } from 'jss';

export const BASE_STYLES: Styles = {
  zIndex0: { zIndex: '0' },
  zIndex10: { zIndex: '10' },
  zIndex20: { zIndex: '20' },
  hit: {
    position: 'absolute',
    zIndex: '30',
    height: '40px',
    width: '60px',
    border: 'solid 4px #1b2231',
  },
  hitSuccess: {
    borderColor: 'green',
  },
  hit01: {
    top: '720px',
    left: '2818px',
  },
  hit02: {
    top: '624px',
    left: '2818px',
  },
  logoTop: {
    left: '5.5%',
    top: '13%',
    width: '40rem',
    zIndex: '0',
  },
  appContainer: { color: '#1b2231' },
  navLink: { color: 'blue' },
  nav: { flexWrap: 'nowrap' },
  background: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '-10',
    backgroundColor: '#1b2231',
  },
  backgroundObject: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
  },
  footer: { zIndex: '100' },
  homeButton: {
    position: 'absolute',
    zIndex: 30,
    cursor: 'pointer',
    userSelect: 'none',
  },
  homeIconImg: { width: '45px', paddingBottom: '10px' },
  browseIconImg: { position: 'absolute', right: 0 },
  mainNavButton: {
    position: 'relative',
    margin: '0 10px',
    cursor: 'pointer',
    userSelect: 'none',
  },
  mainNavButtonInner: {
    color: '#fff',
    margin: 'auto',
    padding: '0 30px 10px 30px',
    borderBottom: '3px solid #6b7a90',
  },
  homeButtonInner: {
    width: '100px',
    display: 'inline-block',
    position: 'absolute',
    bottom: '54px',
    left: '54px',
    padding: '10px 15px 10px 15px',
  },

  browseIcon: {
    width: '100px',
    display: 'inline-block',
    position: 'absolute',
    bottom: '54px',
    right: '50px',
    padding: '10px 15px 10px 15px',
  },

  mainNavButtonImg: { display: 'block', margin: 'auto' },
  planButton: {
    color: '#6b7a90',
    textTransform: 'uppercase',
    paddingTop: '3.5%',
    width: '350px',
    borderBottom: '1px solid #6b7a90',
    fontSize: '12px',
    userSelect: 'none',
    cursor: 'pointer',
  },
  materialButton: {
    color: '#6b7a90',
    textTransform: 'uppercase',
    paddingTop: '10%',
    borderBottom: '1px solid #6b7a90',
    fontSize: '12px',
    userSelect: 'none',
    cursor: 'pointer',
  },
  materialButtonsContainer: {
    width: '150px',
    left: '80px',
    top: '40%',
    position: 'absolute',
  },

  planButtonMin: {
    color: '#6b7a90',
    textTransform: 'uppercase',
    paddingTop: '2%',
    width: '350px',
    borderBottom: '1px solid #6b7a90',
    fontSize: '8px',
    userSelect: 'none',
    cursor: 'pointer',
  },
  flipbookContainer: {
    marginTop: '12%',
  },
  buttonContainer: {
    position: 'absolute',
    top: '10%',
    left: '500px',
    zIndex: '30',
    width: '100%',
  },

  minButtonContainer: {
    position: 'absolute',
    width: '30%',
    bottom: '20%',
    left: '2%',
  },
  planMarker: {
    position: 'absolute',
    backgroundColor: '#fff',
    opacity: 1,
    color: '#000',
    fontSize: '10px',
    padding: '0 5px',
  },
  galleryContainer: { marginTop: '2%' },
  flipbookButtons: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    backgroundColor: 'rgba(0, 0, 0, .2)',
    margin: 3,
    paddingTop: 7,
    color: '#6b7a90',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    opacity: 0.35,
  },
  flipbookButtonsContainer: {
    width: '80%',
    margin: '25px auto 0 auto',
  },
  flipbookActiveButton: {
    opacity: 1,
  },
  iframeContainer: {
    '& iframe': {
      width: '100%',
      height: '100%',
    },
  },
};
