// eslint-disable-next-line no-restricted-imports
import { AppConfiguration, VideoType } from '../types/configuration';
import { HomeButton } from 'components/HomeButton';
import { MotionDiv } from 'components/MotionDiv';
import { AppContext, AppContextType } from 'context';
import React, { Fragment, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import {
  ControlBar,
  ForwardControl,
  Player,
  ReplayControl,
  VolumeMenuButton,
} from 'video-react';
import 'video-react/dist/video-react.css';

export const Video: React.FC<VideoType> = ({
  backgroundImage,
  src,
  videoType,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const { configuration } = useContext<AppContextType>(AppContext);
  const classes = useStyles();

  const localClasses = useLocalStyles(configuration);

  return (
    <MotionDiv>
      <Fragment>
        <Container fluid className="mt-auto h-100">
          <Row className="h-100 overflow-hidden">
            <Col
              className={`offset-md-2 mt-5 ${classes.galleryContainer} ${localClasses.videoContainer}`}
            >
              <Player playsInline>
                {/* Placeholder example video */}
                <source src={src} type={videoType} />

                <ControlBar>
                  {/* "order" is not mapped on the 3rd party library we use */}
                  {/* @ts-ignore */}
                  <ReplayControl seconds={10} order={2} />
                  {/* @ts-ignore */}
                  <ForwardControl seconds={10} order={3} />
                  {/* @ts-ignore */}
                  <VolumeMenuButton order={5} />
                </ControlBar>
              </Player>
            </Col>
          </Row>
        </Container>

        <HomeButton />

        <div className={classes.background}>
          <img
            src={backgroundImage}
            alt="Location"
            className={classes.backgroundObject}
          />
        </div>
      </Fragment>
    </MotionDiv>
  );
};

const useLocalStyles = createUseStyles({
  videoContainer: (configuration: AppConfiguration) => ({
    '& .video-react-control-bar': {
      color: `${configuration.style.primaryColor}`,
      backgroundColor: '#000000a1',
    },
    '& .video-react-play-progress': {
      backgroundColor: `${configuration.style.primaryColor}`,
    },

    '& .video-react': {
      '& .video-react-big-play-button': {
        color: `${configuration.style.primaryColor}`,
        height: '2em',
        width: '2em',
        border: `3px solid ${configuration.style.primaryColor}`,
        borderRadius: '100%',
        margin: 'auto',
        left: 0,
        right: 0,
        top: '50% !important',
      },
      '& .video-react-volume-level': {
        backgroundColor: `${configuration.style.primaryColor}!important`,
      },
      '& .video-react-slider:focus': {
        boxShadow: `0 0 0.5em ${configuration.style.primaryColor}`,
      },

      '& .video-react-fullscreen-control': {
        display: 'none !important',
      },

      '& .video-react-control:before': {
        fontSize: '4.2em !important',
        lineHeight: '1.2em !important',
      },
      '& .video-react-time-control': {
        fontSize: '2em !important',
        lineHeight: '2.5em !important',
      },

      '& .video-react-control': {
        width: '6.5rem !important',
      },

      '& .video-react-control-bar': {
        height: '5em !important',
      },

      '& .video-react-play-progress:before': {
        fontSize: '2em !important',
      },

      '& .video-react-big-play-button:before': {
        marginTop: '5px',
      },

      '& .video-react-volume-bar.video-react-slider-horizontal': {
        height: '1.3em',
      },

      '& .video-react-slider': { backgroundColor: 'transparent' },

      '& .video-react-volume-bar': {
        margin: '1.35em 0 1.35em 3em',
      },

      '& .video-react-volume-menu-button': {
        flex: '0.05 1 auto!important',
      },
    },

    '& .video-react .video-react-progress-holder, .video-react-play-progress,  .video-react-load-progress, .video-react-load-progress div':
      {
        height: '.8em !important',
        borderRadius: '100px',
      },

    '& .video-react-control:hover:before, .video-react-control:focus, .video-react-control:focus:before':
      {
        textShadow: 'none !important',
      },

    '& .video-react-menu': {
      left: '64px!important',
      top: ' 8px!important',
    },
  }),
});
