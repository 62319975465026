// eslint-disable-next-line no-restricted-imports
import { GalleryType } from '../types/configuration';
import { GallerySlideshow } from 'components/GallerySlideshow';
import { HomeButton } from 'components/HomeButton';
import { MotionDiv } from 'components/MotionDiv';
import { AppContext, AppContextType } from 'context';
import React, { Fragment, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export const Gallery: React.FC<GalleryType> = ({ backgroundImage, images }) => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  return (
    <MotionDiv>
      <Fragment>
        <Container fluid className="mt-auto h-100">
          <Row className="h-100 overflow-hidden">
            <Col xl={10} className={`offset-md-2 ${classes.galleryContainer}`}>
              <GallerySlideshow images={images} />
            </Col>
          </Row>
        </Container>

        <HomeButton />

        <div className={classes.background}>
          <img
            src={backgroundImage}
            alt="Gallery"
            className={classes.backgroundObject}
          />
        </div>
      </Fragment>
    </MotionDiv>
  );
};
