import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { Marker } from 'types/configuration';

export type PlanButtonPropsType = {
  buttonText: string;
  delay: number;
  linkedImage: string;
  setCurrentImage: React.Dispatch<React.SetStateAction<string>>;
  setCurrentMarkers: React.Dispatch<React.SetStateAction<Marker[]>>;
  markers: Marker[];
  setZoomedMenu: React.Dispatch<React.SetStateAction<boolean>>;
  zoomedMenu: boolean;
  setCurrentPlan: React.Dispatch<React.SetStateAction<number>>;
  planNumber: number;
  currentPlan: number;
};

export const PlanButton: React.FC<PlanButtonPropsType> = ({
  buttonText,
  delay,
  linkedImage,
  setCurrentImage,
  setCurrentMarkers,
  markers,
  setZoomedMenu,
  zoomedMenu,
  setCurrentPlan,
  planNumber,
  currentPlan,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);
  const { configuration } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 2,
        delay: delay,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      onClick={() => {
        setCurrentImage(linkedImage);
        setCurrentMarkers(markers!);
        setZoomedMenu(false);
        setCurrentPlan(planNumber);
      }}
    >
      <motion.div whileTap={{ scale: 0.95 }}>
        <div
          className={zoomedMenu ? classes.planButton : classes.planButtonMin}
          onClick={() => {
            setCurrentImage(linkedImage);
            setCurrentMarkers(markers!);
          }}
          style={{
            color:
              currentPlan === planNumber
                ? 'white'
                : `${configuration.style.planButton!.color!}`,
          }}
        >
          {buttonText}
        </div>
      </motion.div>
    </motion.div>
  );
};
