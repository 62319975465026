import Bowser from 'bowser';

const WEBKIT_ENGINE_NAME = 'WebKit';

export const toggleFullscreen = () => {
  const browser = Bowser.parse(window.navigator.userAgent);

  const isWebKit = browser.engine.name === WEBKIT_ENGINE_NAME;
  if (isWebKit) {
    //@ts-ignore
    if (!document.webkitFullscreenElement) {
      //@ts-ignore
      document.body.webkitRequestFullscreen!();
    } else {
      //@ts-ignore
      document.webkitExitFullscreen!();
    }
    return;
  }
  if (!document.fullscreenElement) {
    document.body.requestFullscreen();
  } else {
    document.exitFullscreen();
  }
};
