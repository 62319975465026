import { MainNavigation } from 'components/MainNavigation';
import { motion, AnimatePresence } from 'framer-motion';
import React, { Fragment } from 'react';
import { BasicPageProps } from 'types';

export const HomePage: React.FC<BasicPageProps> = ({ backgroundImage }) => {
  return (
    <Fragment>
      <div className="cover-container d-flex w-100 h-100 mx-auto flex-column">
        <AnimatePresence>
          <Fragment>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="home"
              transition={{
                duration: 3,
              }}
            >
              <img src={backgroundImage} alt="Home" className="img-fluid" />
            </motion.div>
            <MainNavigation />
          </Fragment>
        </AnimatePresence>
      </div>
    </Fragment>
  );
};
