export function linearRegressionBottom(scaleValue: number) {
  return 19 / scaleValue + 843 + 0.03 * (1.8 - 2 * scaleValue);
}

export function linearRegressionLeft(scaleValue: number) {
  return (
    1012 -
    Math.ceil(-46 * (scaleValue + (-1 + scaleValue))) / scaleValue -
    Math.cos(26 * (-3032 * scaleValue)) / (0.58 * scaleValue)
  );
}
