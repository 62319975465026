/* eslint-disable no-restricted-imports */
import { AppConfiguration } from '../../types/configuration';
import icoWinClose from './ico-win-close.svg';
import icoWinIcon from './ico-win-icon.svg';
import icoWinMax from './ico-win-max.svg';
import icoWinMin from './ico-win-min.svg';
import { useSpring, animated } from '@react-spring/web';
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import React, { useState, useEffect, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import {
  linearRegressionBottom,
  linearRegressionLeft,
} from 'utils/linearRegressions';

const DEFAULT_WIDTH = 1240;
const DEFAULT_HEIGHT = 877;

// If the app is running in an Electron context, request the keyplan images
// with the protocol expected by the Electron app
const KEYPLAN_IMAGE_PROTOCOL = window.electron ? 'asset:/' : '';

const useGesture = createUseGesture([dragAction, pinchAction]);

function useWindowDimensions() {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export type MarkerWindowProps = {
  image: string;
  left: number;
  setMarkerWindows: React.Dispatch<React.SetStateAction<[] | string[]>>;
  plansData: {
    buttonText: string;
    backgroundImage: string;
    markers: {
      title: string;
      top: string;
      left: string;
    }[];
  }[];
  idx: number;
};

export const MarkerWindow: React.FC<MarkerWindowProps> = ({
  image,
  setMarkerWindows,
  plansData,
  idx,
}) => {
  const { configuration } = useContext<AppContextType>(AppContext);
  const { height, width } = useWindowDimensions();

  function getMarkerPosition() {
    return {
      //transform the % into absolute value based on the viewport size
      top:
        (height / 100) *
        parseInt(
          plansData
            .flatMap((i) => i.markers)
            .filter((i) => i.title === image)[0]
            .top.replace('%', ''),
        ),
      left:
        (width / 100) *
        parseInt(
          plansData
            .flatMap((i) => i.markers)
            .filter((i) => i.title === image)[0]
            .left.replace('%', ''),
        ),
    };
  }

  const ref = React.useRef<HTMLDivElement>(null);

  const [appearance, setAppearance] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 0.25,
    rotateZ: 0,
  }));

  const DEFAULT_WINDOW_STYLE = {
    ...appearance,
    zIndex: 9999,
  };

  const [isWindowZoomed, setIsWindowZoomed] = useState(false);
  const [isWindowIcon, setIsWindowIcon] = useState(false);
  const [windowStyle, setWindowStyle] = useState(DEFAULT_WINDOW_STYLE);
  const [scaleValue, setScaleValue] = useState(0.25);
  const [isPinching, setIsPinching] = useState(false);

  useEffect(() => {
    const gestureHandler = (e: Event) => e.preventDefault();
    document.addEventListener('gestureStart', gestureHandler);
    document.addEventListener('gestureChange', gestureHandler);
    document.addEventListener('gestureEnd', gestureHandler);
    return () => {
      document.removeEventListener('gestureStart', gestureHandler);
      document.removeEventListener('gestureChange', gestureHandler);
      document.removeEventListener('gestureEnd', gestureHandler);
    };
  }, []);

  const handleExpandClick = () => {
    setAppearance.start({ scale: 1 });
    setWindowStyle(DEFAULT_WINDOW_STYLE);
    setAppearance.start({ x: 100, y: -30 });
    setIsWindowZoomed(true);
    setIsWindowIcon(false);
  };

  const handleReduceClick = () => {
    setAppearance.start({ scale: 0.25 });
    setIsWindowZoomed(false);
  };

  const handleCloseClick = () => {
    setMarkerWindows((prev) => prev.filter((gW) => gW !== image));
  };

  function getIconCoordinates() {
    //Display icons in a grid based on their number
    return {
      top: 750 - Math.floor(idx / 8) * 50,
      left: (idx % 8) * 180,
    };
  }

  const handleIconClick = () => {
    setIsWindowIcon(true);
    handleReduceClick();
    setWindowStyle((windowStyle) => {
      return {
        ...windowStyle,
        height: 180,
        width: 700,
        backgroundImage: 'none',
        backgroundColor: '#FFF',
        top: getIconCoordinates().top,
        left: getIconCoordinates().left,
        // opacity: 0.8,
        color: '#324a66',
        borderRadius: 20,
      };
    });
    setAppearance.start({ x: -100, y: 100 });
  };
  const buttonsStyle: React.CSSProperties = isWindowZoomed
    ? //Buttons position if window is zoomed
      {
        transform: 'scale(0.65)',
        position: 'absolute',
        bottom: 870,
        left: 1070,
      }
    : //Buttons position if window is in icon status
    isWindowIcon
    ? {
        transform: 'scale(2.5)',
        position: 'absolute',
        bottom: 55,
        right: 100,
      }
    : //Buttons position if window is normal (not zoomed nor icon)
      {
        transform: `scale(${0.65 / scaleValue})`,
        position: 'absolute',
        //Using linear regression to compute the best approximation
        bottom: linearRegressionBottom(scaleValue),
        left: linearRegressionLeft(scaleValue),
        width: 250,
        visibility: `${isPinching ? 'hidden' : 'visible'}`,
      };

  const classes = useStyles({
    configuration,
    top: getMarkerPosition().top,
    left: getMarkerPosition().left,
    image,
  });

  useGesture(
    {
      onPinch: ({
        origin: [originX, originY],
        first,
        movement: [ms],
        offset: [s, a],
        memo,
      }) => {
        if (!ref.current) {
          return;
        }
        setScaleValue(s);
        setIsWindowZoomed(false);

        if (first) {
          const { width, height, x, y } = ref.current?.getBoundingClientRect();
          const tx = originX - (x + width / 2);
          const ty = originY - (y + height / 2);
          memo = [appearance.x.get(), appearance.y.get(), tx, ty];
        }

        const x = memo[0] - (ms - 1) * memo[2];
        const y = memo[1] - (ms - 1) * memo[3];

        setAppearance.start({
          scale: s,
          rotateZ: Math.round(a / 90) * 90,
          x,
          y,
        });
        return memo;
      },
      onPinchStart: () => {
        setIsPinching(true);
      },
      onPinchEnd: () => {
        setIsPinching(false);
      },
      onDrag: ({ pinching, cancel, offset: [x, y], down }) => {
        if (pinching) return cancel();
        setAppearance.start({ x, y, immediate: down });
      },
    },
    {
      target: ref,
      drag: { from: () => [appearance.x.get(), appearance.y.get()] },
      pinch: { scaleBounds: { min: 0.25, max: 2 }, rubberband: true },
    },
  );
  return (
    <motion.div
      className={`flex fill center container`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={image}
    >
      <animated.div className={classes.card} ref={ref} style={windowStyle}>
        {isWindowIcon && (
          <div style={{ fontSize: 65, marginLeft: 40 }}>{image}</div>
        )}
        <div className="gestureWindowButtons" style={buttonsStyle}>
          {!isWindowIcon && (
            <span onClick={handleIconClick} className={classes.cursorPointer}>
              <img src={icoWinIcon} alt="icon" />
            </span>
          )}
          {!isWindowZoomed && (
            <span onClick={handleExpandClick} className={classes.cursorPointer}>
              <img src={icoWinMax} alt="maximizeIcon" />
            </span>
          )}
          {isWindowZoomed && (
            <span onClick={handleReduceClick} className={classes.cursorPointer}>
              <img src={icoWinMin} alt="minimizeIcon" />
            </span>
          )}
          <span onClick={handleCloseClick} className={classes.cursorPointer}>
            <img src={icoWinClose} alt="closeIcon" />
          </span>
        </div>
      </animated.div>
    </motion.div>
  );
};

type StyleProps = {
  configuration: AppConfiguration;
  top: number;
  left: number;
  image: string;
};

const useStyles = createUseStyles({
  card: ({ configuration, image, top, left }: StyleProps) => ({
    position: 'absolute',
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
    objectFit: 'cover',
    background: `url('${KEYPLAN_IMAGE_PROTOCOL}/image/planimetry/${image}.png')`,
    backgroundColor: `${configuration.style.primaryColor}`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '5px',
    boxShadow: ' 0px 10px 30px -5px rgba(0, 0, 0, 0.3)',
    willChange: 'transform',
    border: '10px solid white',
    cursor: 'grab',
    touchAction: 'none',
    userSelect: 'none',
    webkitUserSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontWeight: 500,
    fontSize: '22px',
    padding: '20px',
    textAlign: 'center',
    color: '#000',
    top: top - 500,
    left: left - 500,
  }),
  cursorPointer: { cursor: 'pointer' },
});
