// eslint-disable-next-line no-restricted-imports
import { BackgroundOnlyType } from '../types/configuration';
import { HomeButton } from 'components/HomeButton';
import { MotionDiv } from 'components/MotionDiv';
import { AppContext, AppContextType } from 'context';
import React, { Fragment, useContext } from 'react';
import { Container } from 'react-bootstrap';

export const BackgroundOnly: React.FC<BackgroundOnlyType> = ({
  backgroundImage,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  return (
    <MotionDiv>
      <Fragment>
        <Container fluid className="mt-auto h-100" />

        <HomeButton />

        <div className={classes.background}>
          <img
            src={backgroundImage}
            alt="Background Only"
            className={classes.backgroundObject}
          />
        </div>
      </Fragment>
    </MotionDiv>
  );
};
