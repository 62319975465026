import 'assets/css/bootstrap.min.css';
import 'assets/css/fontawesome.all.min.css';
import 'assets/css/index.css';
import 'assets/css/line-awesome.min.css';
import { FullscreenAlert } from 'components/Fullscreenalert';
import { AppContext, useAppContext } from 'context';
import React, { useEffect, useState } from 'react';
import { HashRouter } from 'react-router-dom';
import { AppRoutes } from 'routes';

export const App = () => {
  useEffect(() => {
    const handleContextmenu = (e: Event) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, []);

  const appContext = useAppContext();
  const [showAlert, setShowAlert] = useState<boolean>(true);

  return (
    <HashRouter>
      <AppContext.Provider value={appContext}>
        <AppRoutes setShowAlert={setShowAlert} />
        {showAlert && !window.electron && (
          <FullscreenAlert setShowAlert={setShowAlert} />
        )}
      </AppContext.Provider>
    </HashRouter>
  );
};
