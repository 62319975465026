import { App } from 'App';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

if ('serviceWorker' in navigator) {
  // For more info on registering service workers check here:
  // https://web.dev/service-worker-lifecycle/#updates

  navigator.serviceWorker.register('/serviceWorker.js');

  // To force the update of the service worker uncomment the block below
  // In dev mode check: https://web.dev/service-worker-lifecycle/#devtools
  // .then((reg) => {
  //   reg.update();
  // });
}
