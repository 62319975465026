import { AppContext, AppContextType } from 'context';
import { motion, AnimatePresence } from 'framer-motion';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const IdlePage = () => {
  const { configuration } = useContext<AppContextType>(AppContext);

  const navigate = useNavigate();
  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  return (
    <div
      className="cover-container d-flex w-100 h-100 mx-auto flex-column"
      onClick={() => {
        navigate('/');
      }}
    >
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={classes.background}
          key="video"
          transition={{
            duration: 1.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <video
            playsInline
            autoPlay
            muted
            loop
            className={classes.backgroundObject}
          >
            <source
              src={configuration.idle.video.mp4.src}
              type={configuration.idle.video.mp4.type}
            />
            <source
              src={configuration.idle.video.webm.src}
              type={configuration.idle.video.webm.type}
            />
          </video>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
