import { PlanButton } from 'components/PlanButton';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { Marker } from 'types/configuration';

export type PlansButtonsContainerProps = {
  setCurrentImage: React.Dispatch<React.SetStateAction<string>>;
  setCurrentMarkers: React.Dispatch<React.SetStateAction<Marker[]>>;
  dataWithAvailabilities: {
    markers: Marker[];
    buttonText: string;
    backgroundImage: string;
  }[];
  zoomedMenu: boolean;
  setZoomedMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PlansButtonsContainer: React.FC<PlansButtonsContainerProps> = ({
  setCurrentImage,
  setCurrentMarkers,
  dataWithAvailabilities,
  zoomedMenu,
  setZoomedMenu,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();
  const [currentPlan, setCurrentPlan] = useState(-1);
  return (
    <motion.div
      initial={{ top: 100, opacity: 0 }}
      animate={{ top: 0, opacity: 1 }}
      transition={{
        duration: 2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      key={`keyplanLinks${currentPlan}`}
    >
      <div
        className={
          zoomedMenu ? classes.buttonContainer : classes.minButtonContainer
        }
      >
        {dataWithAvailabilities.map((pB, idx) => (
          <PlanButton
            buttonText={pB.buttonText}
            delay={idx / 4}
            key={idx}
            linkedImage={pB.backgroundImage}
            setCurrentImage={setCurrentImage}
            setCurrentMarkers={setCurrentMarkers}
            markers={pB.markers}
            setZoomedMenu={setZoomedMenu}
            zoomedMenu={zoomedMenu}
            setCurrentPlan={setCurrentPlan}
            planNumber={idx}
            currentPlan={currentPlan}
          />
        ))}
      </div>
    </motion.div>
  );
};
