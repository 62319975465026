import { MainNavigationButton } from 'components/MainNavigationButton';
import { AppContext, AppContextType } from 'context';
import { useContext } from 'react';
import { Nav, Navbar } from 'react-bootstrap';

export const MainNavigation = () => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();
  const { configuration } = useContext<AppContextType>(AppContext);

  return (
    <footer
      className={`position-absolute bottom-0  mt-auto w-100 p-5 ${classes.footer}`}
    >
      <Navbar className={`justify-content-center ${classes.nav}`}>
        <Nav>
          {configuration.pages.map((page, id) => {
            return (
              <MainNavigationButton
                linkTo={page.path}
                delay={id / 4}
                imgSrc={page.icon}
                title={page.title}
                key={id}
              />
            );
          })}
        </Nav>
      </Navbar>
    </footer>
  );
};
