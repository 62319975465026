import { AppContext, AppContextType } from 'context';
import { useContext } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { createUseStyles } from 'react-jss';

export type GallerySlideshowProps = {
  images: { original: string; thumbnail: string }[];
};

export const GallerySlideshow: React.FC<GallerySlideshowProps> = ({
  images,
}) => {
  const { configuration } = useContext<AppContextType>(AppContext);

  const useStyles = createUseStyles({
    gallerySlideshow: {
      '& button.active': {
        borderColor: `${configuration.style.primaryColor}`,
      },
      '& .image-gallery-thumbnail:hover': {
        borderColor: `${configuration.style.primaryColor}`,
      },
      '& .image-gallery-icon:hover': {
        color: `${configuration.style.primaryColor}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <ImageGallery items={images} additionalClass={classes.gallerySlideshow} />
  );
};
