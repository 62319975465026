import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { toggleFullscreen } from 'utils/toggleFullScreen';

export const BottomTriangle: React.FC<{
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShowAlert }) => {
  const isTouchDevice = useMemo(() => {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      //@ts-ignore
      navigator.msMaxTouchPoints > 0
    );
  }, []);
  const classes = useStyles();

  const [visible, setVisible] = useState<boolean>(false);
  const [iconClicked, setIconClicked] = useState<boolean>(false);

  return (
    <div
      style={{
        borderRight: `100px solid ${
          visible || isTouchDevice ? '#0000007d' : 'transparent'
        }`,
      }}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      className={classes.bottomTriangle}
    >
      {(visible || isTouchDevice) && (
        <motion.i
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className={`${classes.bottomTriangleIcon} ${
            iconClicked ? 'las la-compress' : 'las la-expand'
          }`}
          onClick={() => {
            toggleFullscreen();
            setIconClicked((prev) => !prev);
            setShowAlert(false);
          }}
        />
      )}
    </div>
  );
};

const useStyles = createUseStyles({
  bottomTriangle: {
    display: 'inline-block',
    borderTop: '100px solid transparent',
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },

  bottomTriangleIcon: {
    color: 'white',
    position: 'absolute',
    right: '-85px',
    bottom: '15px',
    fontSize: '30px',
    cursor: 'pointer',
  },
});
