import axios from 'axios';
import { HomeButton } from 'components/HomeButton';
import { PlansButtonsContainer } from 'components/PlansButtonsContainer';
import { AppContext, AppContextType } from 'context';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { MarkerWindow } from 'pages/KeyPlan/GestureWindow';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AvailabilityDataType } from 'types';
import { Keyplan, KeyplanType, Marker } from 'types/configuration';

const WINDOW_OFFSET = 50;
const WINDOW_OFFSET_MULTIPLIER = 150;

const AVAILABILITY_STATUS_AVAILABLE = 'available';

export const KeyPlan: React.FC<KeyplanType> = ({
  backgroundImage,
  data,
  availabilityColors,
}) => {
  const { configurationUrl } = useContext<AppContextType>(AppContext);

  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const [availabilityData, setAvailabilityData] =
    useState<AvailabilityDataType>([]);

  const [currentImage, setCurrentImage] = useState<string>(backgroundImage);
  const [zoomedMenu, setZoomedMenu] = useState<boolean>(true);

  const [currentMarkers, setCurrentMarkers] = useState<Marker[]>([]);
  const [markerWindows, setMarkerWindows] = useState<string[] | []>([]);

  const fetchAvailabilityData = useCallback(async () => {
    // The app is built as a React app
    if (!window.electron) {
      try {
        const { data } = await axios.get(
          `${configurationUrl}availabilities.json?timestamp=${Math.random()}`,
        );

        setAvailabilityData(data);
      } catch (err) {}
    }
    // The app is build as an Electron app
    else {
      window.electron.ipcRenderer.once(
        'got-availabilities',
        (data: AvailabilityDataType) => setAvailabilityData(data),
      );

      window.electron.ipcRenderer.sendMessage('get-availabilities');
    }
  }, [configurationUrl]);

  const plansData = data;

  useEffect(() => {
    fetchAvailabilityData();
  }, [fetchAvailabilityData]);

  const dataWithAvailabilities = useMemo<Keyplan[]>(() => {
    return plansData.map((pD) => {
      return {
        ...pD,
        markers: pD.markers.map((m) => {
          //Check the version of the file availabilities.json. Leave only the first version when the old structure will be deprecated
          const availability =
            'units' in availabilityData
              ? availabilityData.units.find((u) => u?.name === m.title)
                  ?.availabilityStatus
              : availabilityData.find((u) => u?.name === m.title)
                  ?.availabilityStatus;

          let availabilityColor: string;

          switch (availability) {
            case AVAILABILITY_STATUS_AVAILABLE:
              availabilityColor = availabilityColors.available;
              break;
            case undefined:
              availabilityColor = availabilityColors.unknown;
              break;
            default:
              availabilityColor = availabilityColors.unavailable;
          }

          return { ...m, availability, availabilityColor };
        }),
      };
    });
  }, [
    plansData,
    availabilityData,
    availabilityColors.available,
    availabilityColors.unavailable,
    availabilityColors.unknown,
  ]);

  return (
    <LayoutGroup>
      <div className="cover-container d-flex w-100 h-100 mx-auto flex-column">
        <Container fluid className="mt-auto h-100">
          <Row className="h-100 overflow-hidden">
            <Col
              md="12"
              className="d-flex align-items-center justify-content-center p-0"
            >
              <AnimatePresence>
                {currentMarkers.map((cM, idx) => (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className={classes.planMarker}
                    style={{
                      top: cM.top,
                      left: cM.left,
                      borderBottom: `3px solid ${cM.availabilityColor}`,
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    transition={{
                      duration: 0.8,
                      delay: 1 + idx / 10,
                    }}
                    key={cM.title}
                    onClick={() => {
                      setMarkerWindows((prev) => {
                        return [
                          ...prev.filter((gW) => gW !== cM.title),
                          cM.title,
                        ];
                      });
                    }}
                  >
                    {cM.title}
                  </motion.div>
                ))}
              </AnimatePresence>
            </Col>
          </Row>
        </Container>

        {markerWindows.map((gW, idx) => (
          <MarkerWindow
            image={gW}
            key={gW}
            left={idx * WINDOW_OFFSET_MULTIPLIER + WINDOW_OFFSET}
            setMarkerWindows={setMarkerWindows}
            plansData={plansData}
            idx={idx}
          />
        ))}

        <HomeButton />

        <PlansButtonsContainer
          setCurrentImage={setCurrentImage}
          setCurrentMarkers={setCurrentMarkers}
          dataWithAvailabilities={dataWithAvailabilities}
          zoomedMenu={zoomedMenu}
          setZoomedMenu={setZoomedMenu}
        />

        <AnimatePresence>
          <div className={classes.background}>
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 2,
              }}
              src={currentImage}
              alt={currentImage}
              className={classes.backgroundObject}
              key={currentImage}
            />
          </div>
        </AnimatePresence>
      </div>
    </LayoutGroup>
  );
};
