// eslint-disable-next-line no-restricted-imports
import { FlipbookType } from '../types/configuration';
import { FlipbookComponent } from 'components/FlipbookComponent';
import { HomeButton } from 'components/HomeButton';
import { MotionDiv } from 'components/MotionDiv';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
// import { BrowseIcon } from 'pages/BrowseIcon';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { Container, Row } from 'react-bootstrap';

export const Flipbook: React.FC<FlipbookType> = ({
  backgroundImage,
  defaultWidth,
  defaultHeight,
  variants,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);
  const { configuration } = useContext<AppContextType>(AppContext);

  const [currentMaterials, setCurrentMaterials] = useState<number>(0);
  const flipBook = useRef();

  const classes = useStyles();

  return (
    <MotionDiv>
      <Fragment>
        <Container fluid className="mt-auto h-100">
          <Row className="h-100 overflow-hidden">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={currentMaterials}
              transition={{
                duration: 1,
              }}
            >
              <FlipbookComponent
                width={defaultWidth}
                height={defaultHeight}
                images={variants[currentMaterials].images}
                flipBook={flipBook}
              />
            </motion.div>
          </Row>
        </Container>

        {/* Show variant selector only if there's more than one available */}
        {variants.length > 1 && (
          <div className={classes.materialButtonsContainer}>
            {variants.map((variant, idx) => {
              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 2,
                    delay: idx / 4,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  onClick={() => {
                    setCurrentMaterials(idx);
                    //@ts-ignore
                    flipBook.current.pageFlip().turnToPage(0);
                  }}
                  key={variant.buttonText}
                >
                  <motion.div
                    whileTap={{ scale: 0.95 }}
                    key={variant.buttonText}
                    className={classes.materialButton}
                    style={{
                      color:
                        currentMaterials === idx
                          ? 'white'
                          : `${configuration.style.primaryColor}`,
                    }}
                  >
                    <i className={`las ${variant.icon}`} /> {variant.buttonText}
                  </motion.div>
                </motion.div>
              );
            })}
          </div>
        )}
        <HomeButton />
        {/* <BrowseIcon /> */}

        <div className={classes.background}>
          <img
            src={backgroundImage}
            alt="Materials"
            className={classes.backgroundObject}
          />
        </div>
      </Fragment>
    </MotionDiv>
  );
};
