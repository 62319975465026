import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { BASE_STYLES } from 'styles';
import { AppConfiguration } from 'types/configuration';
import { merge } from 'utils/deepMerge';

export type AppContextType = {
  configuration: AppConfiguration;
  configurationUrl: string;
  useStyles: ReturnType<typeof createUseStyles>;
  ready: boolean;
  updating: boolean;
};

const DEFAULT_VALUE: AppContextType = {
  configuration: {
    pages: [],
    home: { backgroundImage: '', homeIcon: '' },
    idle: {
      video: {
        poster: '',
        webm: {
          src: '',
          type: '',
        },
        mp4: {
          src: '',
          type: '',
        },
      },
    },
    style: {},
  },
  configurationUrl: '',
  useStyles: () => ({}),
  ready: false,
  updating: false,
};

export function useAppContext(): AppContextType {
  const [appContext, setAppContext] = useState<AppContextType>(DEFAULT_VALUE);
  const initialized = useRef<boolean>(false);

  useEffect(() => {
    const init = async () => {
      let configurationUrl = '';

      // The app is built as a React app
      if (!window.electron) {
        const configurationMapping = JSON.parse(
          process.env.REACT_APP_CONFIGURATION_URL ?? '{}',
        );
        configurationUrl =
          configurationMapping[new URL(window.location.href).hostname];

        if (!configurationUrl) {
          return;
        }

        if (navigator?.serviceWorker?.controller) {
          navigator.serviceWorker.controller.postMessage({
            type: 'SET_CONFIGURATION_URL',
            data: configurationUrl,
          });
        }

        const { data: config } = await axios.get(
          `${configurationUrl}config.json`,
        );

        setAppContext({
          configuration: config,
          configurationUrl,
          useStyles: createUseStyles(merge(BASE_STYLES, config.style)),
          ready: true,
        } as AppContextType);
      }
      // The app is build as an Electron app
      else {
        window.electron.ipcRenderer.once(
          'got-config',
          (config: AppConfiguration) =>
            setAppContext({
              configuration: config,
              configurationUrl,
              useStyles: createUseStyles(merge(BASE_STYLES, config.style)),
              ready: true,
              updating: false,
            } as AppContextType),
        );

        window.electron.ipcRenderer.once('updating-data', () =>
          setAppContext({ ...DEFAULT_VALUE, updating: true }),
        );

        window.electron.ipcRenderer.once(
          'error-getting-config',
          (configFilePath: string) =>
            alert(`No configuration found (${configFilePath})`),
        );

        window.electron.ipcRenderer.sendMessage('get-config');
      }
    };

    if (!initialized.current) {
      initialized.current = true;
      init();
    }
  }, []);

  return appContext;
}

export const AppContext = React.createContext<AppContextType>(DEFAULT_VALUE);
