// eslint-disable-next-line no-restricted-imports
import { WebviewType } from '../types/configuration';
import { HomeButton } from 'components/HomeButton';
import { MotionDiv } from 'components/MotionDiv';
import { AppContext, AppContextType } from 'context';
import React, { Fragment, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'video-react/dist/video-react.css';

export const Webview: React.FC<WebviewType> = ({ backgroundImage, url }) => {

  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();

  return (
    <MotionDiv>
      <Fragment>
        <Container fluid className="mt-auto h-100">
          <Row className="h-100 overflow-hidden">
            <Col
              className={`offset-md-2 embed-responsive-item ${classes.iframeContainer}`}
            >
              <iframe src={url} title="Virtual Tour" />
            </Col>
          </Row>
        </Container>

        <HomeButton />

        <div className={classes.background}>
          <img
            src={backgroundImage}
            alt="Location"
            className={classes.backgroundObject}
          />
        </div>
      </Fragment>
    </MotionDiv>
  );
};
