import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { Row } from 'react-bootstrap';
import HTMLFlipBook from 'react-pageflip';

export type FlipbookComponentProps = {
  width: number;
  height: number;
  images: string[];
  flipBook: React.MutableRefObject<undefined>;
};

export const FlipbookComponent: React.FC<FlipbookComponentProps> = ({
  width,
  height,
  images,
  flipBook,
}) => {
  const { useStyles } = useContext<AppContextType>(AppContext);

  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <div className={classes.flipbookContainer}>
      {/* @ts-ignore */}
      <HTMLFlipBook
        width={width}
        height={height}
        ref={flipBook}
        className={classes.flipbook}
        onFlip={(page) => {
          setCurrentPage(page.data);
        }}
      >
        {images.map((image, id) => (
          <div key={id}>
            <img
              src={image}
              alt={`${id}`}
              style={{ width: width, height: height }}
            />
          </div>
        ))}
      </HTMLFlipBook>
      <Row
        className={`d-flex justify-content-center ${classes.flipbookButtonsContainer}`}
      >
        {images.map((image, id) => {
          //We keep only odd numbers dots as, in terms of navigation, there's no need for the even ones too
          if (id % 2 === 0) {
            return null;
          }
          return (
            <motion.div
              whileTap={{ scale: 0.8 }}
              key={image}
              onClick={() => {
                //@ts-ignore
                flipBook.current.pageFlip().turnToPage(id);
                if (flipBook.current) {
                  setCurrentPage(
                    //@ts-ignore
                    flipBook.current.pageFlip().getCurrentPageIndex(),
                  );
                }
              }}
              className={`${classes.flipbookButtons} 
              ${currentPage === id - 1 && classes.flipbookActiveButton}`}
            >
              {id}
            </motion.div>
          );
        })}
      </Row>
    </div>
  );
};
